.terms p {
  margin-bottom: 10px;
}

.terms ul {
  list-style-type: disc;
  margin-bottom: 10px;
  padding-inline-start: 24px;
}

