@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

@import './ckeditor.scss';

@layer base {
  .layout-marketing {
    font-family: 'Outfit', 'Inter var', 'Roboto', 'Helvetica Neue', Arial, sans-serif;

    @apply text-hs-grey-black bg-hs-grey-light;
  }
}

@layer components {
  .layout-marketing {
    .h1 {
      @apply text-64 leading-none font-medium;
    }

    .h2 {
      @apply text-48 leading-none font-medium;
    }

    .h3 {
      @apply text-36 leading-none font-semibold;
    }

    .h4 {
      @apply text-28 leading-none font-medium;
    }

    .h5 {
      @apply text-20 leading-7 font-medium;
    }

    .body-lg {
      @apply text-24 leading-9 font-normal;
    }

    .body,
    .body-md {
      @apply text-18 leading-7 font-medium;
    }

    .body-sm {
      @apply text-16 leading-6 font-normal;
    }

    .body-xs {
      @apply text-12 leading-4 font-medium;
    }
  }
}

@layer utilities {
}

@tailwind base;
@tailwind components;
@tailwind utilities;
