dialog.loading {
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0);
}

dialog.loading::backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.loading > div {
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
}